html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

th span {
    font-weight: bold;
}

.datagrid-body tr {
    white-space: 'normal';
    word-wrap: 'break-word';
}

th {
    white-space: normal !important;
    height: unset !important;
}

th span {
    text-transform: none !important;
    white-space: normal !important;
    height: unset !important;
}

th button {
    text-transform: none !important;
    white-space: normal !important;
    height: unset !important;
}

.react-flexbox-grid-col {
    /* padding-left: 16px !important; */
    padding-right: 32px !important;
}

h1 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

h2 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

h3 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

h4 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

h5 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

h6 {
    margin-block-start: 0.1em;
    margin-block-end: 0.25em;
}

/*
  prevent scrollbar on empty textareas in IE
*/
textarea {
    overflow: auto;
}

/*
Properties below for for a css transform-only spinner.
*/
.spinnyloader,
.spinnyloader:before,
.spinnyloader:after {
    background: #808080;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.spinnyloader {
    color: #808080;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.spinnyloader:before,
.spinnyloader:after {
    position: absolute;
    top: 0;
    content: '';
}

.spinnyloader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinnyloader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

/*
loader

*/

.loader,
.loader:after {
    border-radius: 50%;
    width: 1.6em;
    height: 1.6em;
}

.loader {
    margin: 14px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(209, 209, 209, 0);
    border-right: 0.3em solid rgba(209, 209, 209, 0);
    border-bottom: 0.3em solid rgba(209, 209, 209, 0);
    border-left: 0.3em solid #242424;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

::-ms-clear {
    display: none;
}

.casetivity-off-screen {
    position: absolute;
    left: -10000px;
    top: auto;
    height: 0;
    width: 0;
    overflow: hidden;
}

.flip-horizontal {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph;
    /*IE*/
    filter: fliph;
    /*IE*/
}

.casetivity-linkbutton {
    background: none !important;
    border: none;
    padding: 0 !important;

    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    cursor: pointer;
}

.casetivity-linkbutton:hover {
    text-decoration: underline;
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.angledLastTh {
    z-index: 1;
    position: relative;
    overflow: visible;
}

.angledLastTh:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(30% + 7px);
    width: 100%;
    height: 100%;
    background-color: inherit;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-35deg);
    -ms-transform: skew(-35deg);
    transform: skew(-35deg);
    z-index: -1;
}

@media screen and (min-width: 0px) {
    .casetivity-smallonly {
        display: block;
    }

    /* show it on small screens */
    .casetivity-largeonly {
        display: none;
    }

    /* hide it on small screens */
}

@media screen and (min-width: 700px) {
    .casetivity-smallonly {
        display: none;
    }

    /* hide it on large screens */
    .casetivity-largeonly {
        display: block;
    }

    /* show it on large screens */
}

@media print {
    .MuiTablePagination-actions {
        display: none !important;
    }
    label .Mui-disabled {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .casetivity-select {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    input {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    select {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    textarea {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    #maincontent {
        max-width: unset !important;
        width: '100%' !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    body {
        zoom: 75%;
    }

    .casetivity-smallonly {
        display: none;
    }

    .casetivity-largeonly {
        display: block;
    }

    /* This is a much better name. TODO: remove .casetivity-hide-printing */
    .casetivity-noprint {
        display: none !important;
    }

    .casetivity-hide-printing {
        display: none !important;
    }

    th {
        color: black !important;
        text-shadow: none !important;
        border: none !important;
    }

    /*
    below is to prevent images from being split between pages.
    This is especially important for QR codes.
    we can't have 'display: flex' as a parent for this to work, hence changing
    MuiGridRoot to display: block;
    */
    img {
        page-break-inside: avoid;
        break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
        display: block;
    }

    .MuiGrid-root {
        display: block !important;
    }
}

/*
 Allows touch scroll of page, when touching dashboard items
 https://github.com/react-grid-layout/react-grid-layout/issues/637
*/
.react-grid-item {
    touch-action: initial !important;
}

.react-grid-item .drag-handle {
    touch-action: none;
}

/* For overlay when editing list views inline. */
.blanket {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: black;
    opacity: 0.5;
    z-index: 100;
}

.casetivity-code-block {
    font-family: Monaco, monospace;
    line-height: 100%;
    background-color: #eee;
    padding: 0.2em;
    letter-spacing: -0.05em;
    word-break: normal;
    border-radius: 5px;
}
