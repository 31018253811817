.wzsteps {
    color: black;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.wzsteps a {
    color: black;
    text-decoration: none;
    font-size: 14px;
    line-height: 2;
}

.wzsteps li {
    background: lightgrey;
    padding: 3px 15px;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
}

.wzsteps li:last-child {
    border-right: none;
}

.wzactive {
    /* border-bottom: 2px solid #ec5990 !important; */
    color: black !important;
    border-bottom: none !important;
    background: #fafafa !important;
}
